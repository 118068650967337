<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isModalOpen"
		ok-variant="success"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-title="FormMSG(29801, 'Save')"
		:cancel-title="FormMSG(212302, 'Cancel')"
		:title="FormMSG(26700, 'Picture capture')"
		:class="rendComponentClass"
		:ok-disabled="isLoading"
		:cancel-disabled="isLoading"
		@ok.prevent="saveCapturedPics"
		@cancel="handleCloseModal('cancel')"
		@close="handleCloseModal('close')"
		@hidden="handleCloseModal('hidden')"
		modal-class="mui-animation"
		:fade="false"
	>
		<div ref="containerCaptureModal">
			<b-row>
				<b-col v-if="capturedPicsList.length > 0" md="4">
					<CapturedImgsList :manager="manager" />
				</b-col>
				<b-col>
					<DesktopImgUpload
						:acceptDocx="false"
						:acceptExlx="false"
						:acceptPptx="false"
						:manager="manager"
						@change="handleDesktopImageSelected"
						@change-filename="handleChangeFileName"
					/>
					<separator :label="FormMSG(1, 'or')" />
					<div class="centered_content full push_t_20">
						<b-button @click="openWebCamCaptureModal">
							{{ FormMSG(342234, 'Use webcam') }}
						</b-button>
					</div>
					<b-modal
						header-class="header-class-modal-doc-package"
						ref="modal"
						v-model="isWebCamCaptureModalOpen"
						ok-variant="success"
						size="lg"
						:title="FormMSG(200, 'Picture capture')"
						:ok-title="FormMSG(201, 'Save')"
						:cancel-title="FormMSG(202, 'Cancel')"
						@ok="saveWebCamCapture"
						@cancel="isWebCamCaptureModalOpen = false"
						@hidden="isWebCamCaptureModalOpen = false"
						modal-class="mui-animation"
						:fade="false"
					>
						<CaptureWebCam :manager="manager" @change="handleCaptureChange" />
					</b-modal>
				</b-col>
			</b-row>
		</div>

		<template #modal-ok>
			<div>
				<b-spinner v-if="isLoading" small />
				{{ FormMSG(29801, 'Save') }}
			</div>
		</template>
	</b-modal>
</template>

<script>
import Compressor from 'compressorjs';
import { isNil, isBase64 } from '@/shared/utils';
import { isFileExtImage } from '@/shared/helpers';
import { store } from '@/store';
import { b64toFile, getExtensionFromBase64, base64ToBlob } from '@/components/Packages/Captures/capture.utils';

import DesktopImgUpload from '@/components/Packages/Captures/components/DesktopImg';
import CaptureWebCam from '@/components/Packages/Captures/components/CaptureWebCam';
import CapturedImgsList from '@/components/Packages/Captures/components/CapturedImgsList';

import languageMessages from '@/mixins/languageMessages';
import modalMixin from '@/mixins/modal.mixin';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'PackagesCaptureComponentsCaptureModal',
	components: { DesktopImgUpload, CaptureWebCam, CapturedImgsList },
	mixins: [modalMixin, languageMessages, globalMixin],
	props: {
		isUsed: {
			type: String,
			required: false
		},
		manager: {
			type: Object,
			required: true,
			default: () => {}
		},
		/**
		 * function for generating a key for each upload file
		 * /!\/!\/!\ this function should be a Promise /!\/!\/!\
		 */
		generateDynamicParentsIdPerDoc: {
			type: Function,
			required: false,
			default: null
		}
	},
	data() {
		return {
			capturedImageWebCam: null,
			isLoading: false,
			uploadFileCount: 1,
			fileNames: []
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		capturedPicsList() {
			return this.manager.states.capturedPicsList;
		},

		/**
		 * @return {Object}
		 */
		rendComponentClass() {
			return {
				'has-multiple-images': this.manager.states.multiple
			};
		},

		isWebCamCaptureModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isWebCamCaptureModalOpen;
			},
			/**
			 * @param {Boolean}
			 */
			set(status) {
				this.manager.dispatch('toggleWebCamCaptureModal', status);
			}
		},

		/**
		 * @return {Object }
		 */
		captureOptions() {
			return this.manager.states.options;
		}
	},
	methods: {
		handleChangeFileName(payload) {
			this.fileNames = payload || [];
		},
		/**
		 * @param {String} eventType
		 */
		handleCloseModal(eventType) {
			this.$emit(eventType, true);

			if (this.isUsed === 'waste') {
				this.images = [];
				this.picturesToKeep = [];
				this.savedImages = [];
				this.fileNames = [];
				this.manager.dispatch('toggleCaptureModal', false);
				this.$emit('handleCloseModalFile', false);
			} else {
				this.images = [];
				this.picturesToKeep = [];
				this.savedImages = [];
				this.fileNames = [];
				this.manager.dispatch('toggleCaptureModal', false);
			}
		},

		/**
		 * @param {Array} images
		 * @param {Number} id
		 */
		async loopImages(images) {
			for (let i = 0; i < images.length; i++) {
				const img = images[i];
				if (!isBase64(img)) {
					this.manager.dispatch('setImgsListPayload', img);
				} else {
					await this.saveImage(img, i);
				}
			}
		},

		async saveCapturedPics() {
			this.isLoading = true;
			await this.loopImages(this.capturedPicsList);
			this.manager.dispatch('clearCapturedPicsList');
			this.manager.emit('change', this.manager.states.imgsListPayload);
			if (this.isUsed === 'waste') {
				this.$emit('change', this.manager.states.imgsListPayload);
			}
			this.isLoading = false;
			this.handleCloseModal('close');
		},

		getCompressedImage(base64ImgFile) {
			return new Promise((resolve, reject) => {
				new Compressor(base64ToBlob(base64ImgFile), {
					quality: 0.8,
					success(result) {
						resolve(result);
					},
					error(err) {
						reject(err);
					}
				});
			});
		},

		async saveImage(imgData, index) {
			if (!isBase64(imgData)) return;

			const base64Data = imgData.split(';base64,');
			// const ext = base64Data[0].replace('data:image/', '')
			const ext = getExtensionFromBase64(imgData);
			const file = b64toFile(base64Data[1], this.fileNames[index], ext);

			let formData = new FormData();
			formData.append('uploadimage', file);

			if (isFileExtImage(ext)) {
				try {
					const compressedFile = await this.getCompressedImage(imgData);
					formData.set('uploadimage', compressedFile);
				} catch (err) {
					console.log({ COMPRESSION_ERR: err.message });
				}
			}

			const { parentType, parentSubType, parentId } = this.captureOptions;
			if (!isNil(parentType)) formData.append('parentType', parentType);
			if (!isNil(parentSubType)) formData.append('parentSubType', parentSubType);

			const generatedParentId =
				this.uploadFileCount > 1 && !isNil(this.generateDynamicParentsIdPerDoc) ? await this.generateDynamicParentsIdPerDoc() : parentId;

			if (!isNil(parentId)) formData.append('parentId', generatedParentId);

			try {
				const savedImg = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.uploadFileCount = this.uploadFileCount + 1;
				this.manager.dispatch('setImgsListPayload', savedImg);
				this.isLoading = false;
			} catch (e) {
				console.error({ e });
			}
		},

		openWebCamCaptureModal() {
			this.isWebCamCaptureModalOpen = true;
		},

		handleDesktopImageSelected(img) {
			this.manager.dispatch('addCapturedPicsList', img);
		},

		saveWebCamCapture() {
			this.manager.dispatch('addCapturedPicsList', this.capturedImageWebCam);
		},

		handleCaptureChange(capture) {
			// console.log({ handleCaptureChange: capture });
			this.capturedImageWebCam = capture;
			// this.manager.dispatch('addCapturedPicsList', capture)
		}
	}
};
</script>
